

$lightOrange: #F97304;
$darkOrange:#D44206;

$darkBlue1 : #023D67;
$darkBlue2: #03749C;
$lightBlue1 : #02B1D7;
$lightBlue2 : #09D4EF;


// $primary: #FF0000;
$primary: #F97304;
$secundary: #D44206 ;


$blue: #023D67;
// $body-bg: rgb(245, 245, 245);
$body-color: $darkBlue1;



// lightOrange = 249,115,4 = #F97304
// darkOrange = 212,66,6 = #D44206 
// darkBlue1 = 2,61,103 =  #023D67
// darkBlue2 = 3,116,156 = #03749C
// lightBlue1 = 21,177,215 = #02B1D7
// lightBlue2 = 92,212,239 = #09D4EF

@import url("../node_modules/bootstrap-icons/font//bootstrap-icons.css");


@import "../node_modules/bootstrap/scss/bootstrap.scss";

.section-skills {
    background: #F5F5F5;
}

.section-portfolio {
    background: #FFFFFF;
}

.section-footer {
    background: $lightBlue1;
    color:white;
    padding-top:10px;
    padding-bottom:10px;
}

.section-intro-me {
    background: #F5F5F5;
    // height: 50vh;
    min-height: 300px;
    // background-image: url('./embed-images/background.jpg');
    // padding: 100px 0;
    // position: relative;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-size: cover;

}

.section-about-me {
    // height: 400px;
    // height: 50vh;
    min-height: 300px;
    padding: 50px 0;
}

.section-experience {
    background: white;
}

.contact-me {
    background-image: url('./embed-images/background.jpg');
    padding: 100px 0;
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

}

.card {
    background-color: #F5F5F5;
}